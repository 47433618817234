import { default as ai_45form_45builder7WzRJA0Du7Meta } from "/app/pages/ai-form-builder.vue?macro=true";
import { default as editwQVCmQcpMNMeta } from "/app/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexLta7GnB5koMeta } from "/app/pages/forms/[slug]/index.vue?macro=true";
import { default as indexiAwFCXujVZMeta } from "/app/pages/forms/[slug]/show/index.vue?macro=true";
import { default as shareMU8dKw0g4EMeta } from "/app/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsZfUFjbagddMeta } from "/app/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionseBMBCJMLo9Meta } from "/app/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as shownMX8mEbjaWMeta } from "/app/pages/forms/[slug]/show.vue?macro=true";
import { default as guestnNfDCKGrpJMeta } from "/app/pages/forms/create/guest.vue?macro=true";
import { default as index9L3mF65oE2Meta } from "/app/pages/forms/create/index.vue?macro=true";
import { default as homeH3NsyxtJwrMeta } from "/app/pages/home.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as emailCBPRBFsa6RMeta } from "/app/pages/password/email.vue?macro=true";
import { default as _91token_93aZSSnxQtLOMeta } from "/app/pages/password/reset/[token].vue?macro=true";
import { default as pricingpTmAIboEh0Meta } from "/app/pages/pricing.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as accountFbmhd9M064Meta } from "/app/pages/settings/account.vue?macro=true";
import { default as adminsNxuUjdaTvMeta } from "/app/pages/settings/admin.vue?macro=true";
import { default as billingu0Xntxr74XMeta } from "/app/pages/settings/billing.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as passwordZQhuzffxi0Meta } from "/app/pages/settings/password.vue?macro=true";
import { default as profile8S52gQnHEjMeta } from "/app/pages/settings/profile.vue?macro=true";
import { default as workspace5sI0HuMVKVMeta } from "/app/pages/settings/workspace.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as erroremsGw7wmnJMeta } from "/app/pages/subscriptions/error.vue?macro=true";
import { default as successrVysLx7SdsMeta } from "/app/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93LAzaU3fYJ8Meta } from "/app/pages/templates/[slug].vue?macro=true";
import { default as index6xet4YTGVdMeta } from "/app/pages/templates/index.vue?macro=true";
import { default as _91slug_93ud615Anym0Meta } from "/app/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesbQ693vj68bMeta } from "/app/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93Zaiz6yTLiTMeta } from "/app/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsRRu4oncQRtMeta } from "/app/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: ai_45form_45builder7WzRJA0Du7Meta?.name ?? "ai-form-builder",
    path: ai_45form_45builder7WzRJA0Du7Meta?.path ?? "/ai-form-builder",
    meta: ai_45form_45builder7WzRJA0Du7Meta || {},
    alias: ai_45form_45builder7WzRJA0Du7Meta?.alias || [],
    redirect: ai_45form_45builder7WzRJA0Du7Meta?.redirect || undefined,
    component: () => import("/app/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: editwQVCmQcpMNMeta?.name ?? "forms-slug-edit",
    path: editwQVCmQcpMNMeta?.path ?? "/forms/:slug()/edit",
    meta: editwQVCmQcpMNMeta || {},
    alias: editwQVCmQcpMNMeta?.alias || [],
    redirect: editwQVCmQcpMNMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexLta7GnB5koMeta?.name ?? "forms-slug",
    path: indexLta7GnB5koMeta?.path ?? "/forms/:slug()",
    meta: indexLta7GnB5koMeta || {},
    alias: indexLta7GnB5koMeta?.alias || [],
    redirect: indexLta7GnB5koMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    path: shownMX8mEbjaWMeta?.path ?? "/forms/:slug()/show",
    children: [
  {
    name: indexiAwFCXujVZMeta?.name ?? "forms-slug-show",
    path: indexiAwFCXujVZMeta?.path ?? "",
    meta: indexiAwFCXujVZMeta || {},
    alias: indexiAwFCXujVZMeta?.alias || [],
    redirect: indexiAwFCXujVZMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: shareMU8dKw0g4EMeta?.name ?? "forms-slug-show-share",
    path: shareMU8dKw0g4EMeta?.path ?? "share",
    meta: shareMU8dKw0g4EMeta || {},
    alias: shareMU8dKw0g4EMeta?.alias || [],
    redirect: shareMU8dKw0g4EMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: statsZfUFjbagddMeta?.name ?? "forms-slug-show-stats",
    path: statsZfUFjbagddMeta?.path ?? "stats",
    meta: statsZfUFjbagddMeta || {},
    alias: statsZfUFjbagddMeta?.alias || [],
    redirect: statsZfUFjbagddMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: submissionseBMBCJMLo9Meta?.name ?? "forms-slug-show-submissions",
    path: submissionseBMBCJMLo9Meta?.path ?? "submissions",
    meta: submissionseBMBCJMLo9Meta || {},
    alias: submissionseBMBCJMLo9Meta?.alias || [],
    redirect: submissionseBMBCJMLo9Meta?.redirect || undefined,
    component: () => import("/app/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
],
    name: shownMX8mEbjaWMeta?.name ?? undefined,
    meta: shownMX8mEbjaWMeta || {},
    alias: shownMX8mEbjaWMeta?.alias || [],
    redirect: shownMX8mEbjaWMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/[slug]/show.vue").then(m => m.default || m)
  },
  {
    name: guestnNfDCKGrpJMeta?.name ?? "forms-create-guest",
    path: guestnNfDCKGrpJMeta?.path ?? "/forms/create/guest",
    meta: guestnNfDCKGrpJMeta || {},
    alias: guestnNfDCKGrpJMeta?.alias || [],
    redirect: guestnNfDCKGrpJMeta?.redirect || undefined,
    component: () => import("/app/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: index9L3mF65oE2Meta?.name ?? "forms-create",
    path: index9L3mF65oE2Meta?.path ?? "/forms/create",
    meta: index9L3mF65oE2Meta || {},
    alias: index9L3mF65oE2Meta?.alias || [],
    redirect: index9L3mF65oE2Meta?.redirect || undefined,
    component: () => import("/app/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: homeH3NsyxtJwrMeta?.name ?? "home",
    path: homeH3NsyxtJwrMeta?.path ?? "/home",
    meta: homeH3NsyxtJwrMeta || {},
    alias: homeH3NsyxtJwrMeta?.alias || [],
    redirect: homeH3NsyxtJwrMeta?.redirect || undefined,
    component: () => import("/app/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect || undefined,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: emailCBPRBFsa6RMeta?.name ?? "password-email",
    path: emailCBPRBFsa6RMeta?.path ?? "/password/email",
    meta: emailCBPRBFsa6RMeta || {},
    alias: emailCBPRBFsa6RMeta?.alias || [],
    redirect: emailCBPRBFsa6RMeta?.redirect || undefined,
    component: () => import("/app/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: _91token_93aZSSnxQtLOMeta?.name ?? "password-reset-token",
    path: _91token_93aZSSnxQtLOMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93aZSSnxQtLOMeta || {},
    alias: _91token_93aZSSnxQtLOMeta?.alias || [],
    redirect: _91token_93aZSSnxQtLOMeta?.redirect || undefined,
    component: () => import("/app/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: pricingpTmAIboEh0Meta?.name ?? "pricing",
    path: pricingpTmAIboEh0Meta?.path ?? "/pricing",
    meta: pricingpTmAIboEh0Meta || {},
    alias: pricingpTmAIboEh0Meta?.alias || [],
    redirect: pricingpTmAIboEh0Meta?.redirect || undefined,
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect || undefined,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register",
    path: registermu8R7rSP5uMeta?.path ?? "/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect || undefined,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    path: settingsLwEYOlkQxrMeta?.path ?? "/settings",
    children: [
  {
    name: accountFbmhd9M064Meta?.name ?? "settings-account",
    path: accountFbmhd9M064Meta?.path ?? "account",
    meta: accountFbmhd9M064Meta || {},
    alias: accountFbmhd9M064Meta?.alias || [],
    redirect: accountFbmhd9M064Meta?.redirect || undefined,
    component: () => import("/app/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminsNxuUjdaTvMeta?.name ?? "settings-admin",
    path: adminsNxuUjdaTvMeta?.path ?? "admin",
    meta: adminsNxuUjdaTvMeta || {},
    alias: adminsNxuUjdaTvMeta?.alias || [],
    redirect: adminsNxuUjdaTvMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingu0Xntxr74XMeta?.name ?? "settings-billing",
    path: billingu0Xntxr74XMeta?.path ?? "billing",
    meta: billingu0Xntxr74XMeta || {},
    alias: billingu0Xntxr74XMeta?.alias || [],
    redirect: billingu0Xntxr74XMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: index11eXGkSJBcMeta?.name ?? "settings",
    path: index11eXGkSJBcMeta?.path ?? "",
    meta: index11eXGkSJBcMeta || {},
    alias: index11eXGkSJBcMeta?.alias || [],
    redirect: index11eXGkSJBcMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordZQhuzffxi0Meta?.name ?? "settings-password",
    path: passwordZQhuzffxi0Meta?.path ?? "password",
    meta: passwordZQhuzffxi0Meta || {},
    alias: passwordZQhuzffxi0Meta?.alias || [],
    redirect: passwordZQhuzffxi0Meta?.redirect || undefined,
    component: () => import("/app/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profile8S52gQnHEjMeta?.name ?? "settings-profile",
    path: profile8S52gQnHEjMeta?.path ?? "profile",
    meta: profile8S52gQnHEjMeta || {},
    alias: profile8S52gQnHEjMeta?.alias || [],
    redirect: profile8S52gQnHEjMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspace5sI0HuMVKVMeta?.name ?? "settings-workspace",
    path: workspace5sI0HuMVKVMeta?.path ?? "workspace",
    meta: workspace5sI0HuMVKVMeta || {},
    alias: workspace5sI0HuMVKVMeta?.alias || [],
    redirect: workspace5sI0HuMVKVMeta?.redirect || undefined,
    component: () => import("/app/pages/settings/workspace.vue").then(m => m.default || m)
  }
],
    name: settingsLwEYOlkQxrMeta?.name ?? undefined,
    meta: settingsLwEYOlkQxrMeta || {},
    alias: settingsLwEYOlkQxrMeta?.alias || [],
    redirect: settingsLwEYOlkQxrMeta?.redirect || undefined,
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: erroremsGw7wmnJMeta?.name ?? "subscriptions-error",
    path: erroremsGw7wmnJMeta?.path ?? "/subscriptions/error",
    meta: erroremsGw7wmnJMeta || {},
    alias: erroremsGw7wmnJMeta?.alias || [],
    redirect: erroremsGw7wmnJMeta?.redirect || undefined,
    component: () => import("/app/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successrVysLx7SdsMeta?.name ?? "subscriptions-success",
    path: successrVysLx7SdsMeta?.path ?? "/subscriptions/success",
    meta: successrVysLx7SdsMeta || {},
    alias: successrVysLx7SdsMeta?.alias || [],
    redirect: successrVysLx7SdsMeta?.redirect || undefined,
    component: () => import("/app/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LAzaU3fYJ8Meta?.name ?? "templates-slug",
    path: _91slug_93LAzaU3fYJ8Meta?.path ?? "/templates/:slug()",
    meta: _91slug_93LAzaU3fYJ8Meta || {},
    alias: _91slug_93LAzaU3fYJ8Meta?.alias || [],
    redirect: _91slug_93LAzaU3fYJ8Meta?.redirect || undefined,
    component: () => import("/app/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: index6xet4YTGVdMeta?.name ?? "templates",
    path: index6xet4YTGVdMeta?.path ?? "/templates",
    meta: index6xet4YTGVdMeta || {},
    alias: index6xet4YTGVdMeta?.alias || [],
    redirect: index6xet4YTGVdMeta?.redirect || undefined,
    component: () => import("/app/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ud615Anym0Meta?.name ?? "templates-industries-slug",
    path: _91slug_93ud615Anym0Meta?.path ?? "/templates/industries/:slug()",
    meta: _91slug_93ud615Anym0Meta || {},
    alias: _91slug_93ud615Anym0Meta?.alias || [],
    redirect: _91slug_93ud615Anym0Meta?.redirect || undefined,
    component: () => import("/app/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: my_45templatesbQ693vj68bMeta?.name ?? "templates-my-templates",
    path: my_45templatesbQ693vj68bMeta?.path ?? "/templates/my-templates",
    meta: my_45templatesbQ693vj68bMeta || {},
    alias: my_45templatesbQ693vj68bMeta?.alias || [],
    redirect: my_45templatesbQ693vj68bMeta?.redirect || undefined,
    component: () => import("/app/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Zaiz6yTLiTMeta?.name ?? "templates-types-slug",
    path: _91slug_93Zaiz6yTLiTMeta?.path ?? "/templates/types/:slug()",
    meta: _91slug_93Zaiz6yTLiTMeta || {},
    alias: _91slug_93Zaiz6yTLiTMeta?.alias || [],
    redirect: _91slug_93Zaiz6yTLiTMeta?.redirect || undefined,
    component: () => import("/app/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsRRu4oncQRtMeta?.name ?? "terms-conditions",
    path: terms_45conditionsRRu4oncQRtMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsRRu4oncQRtMeta || {},
    alias: terms_45conditionsRRu4oncQRtMeta?.alias || [],
    redirect: terms_45conditionsRRu4oncQRtMeta?.redirect || undefined,
    component: () => import("/app/pages/terms-conditions.vue").then(m => m.default || m)
  }
]