
import * as dummyRuntime$IXoDKyADFB from '/app/lib/images/dummy-image-provider.js'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "dummy",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['dummy']: { provider: dummyRuntime$IXoDKyADFB, defaults: undefined }
}
        